:root {
  --font-family: "Manrope", sans-serif;
  -colors-prototype-1000: #fff;
  --colors-prototype-900: #f0f0f0;
  --colors-prototype-800: #ccc;
  --colors-prototype-700: #b3b3b3;
  --colors-prototype-600: #999;
  --colors-prototype-500: #808080;
  --colors-prototype-400: #666;
  --colors-prototype-300: #4d4d4d;
  --colors-prototype-200: #333;
  --colors-prototype-100: #191919;
  --colors-grey-900: #101028;
  --colors-grey-800: #1d1d39;
  --colors-grey-700: #343454;
  --colors-grey-600: #474767;
  --colors-grey-500: #666685;
  --colors-grey-400: #9898b3;
  --colors-grey-300: #d0d0dd;
  --colors-grey-200: #e4e4ec;
  --colors-grey-100: #f0f0f4;
  --colors-primary-900: #42307d;
  --colors-primary-800: #53389e;
  --colors-primary-700: #6941c6;
  --colors-primary-600: #7f56d9;
  --colors-primary-500: #9e77ed;
  --colors-primary-400: #b692f6;
  --colors-primary-300: #d6bbfb;
  --colors-primary-200: #e9d7fe;
  --colors-primary-100: #f4ebff;
  --colors-success-900: #19854b;
  --colors-success-800: #18a95c;
  --colors-success-700: #17d36f;
  --colors-success-600: #15ef7b;
  --colors-success-500: #2efa8d;
  --colors-success-400: #53fda2;
  --colors-success-300: #84ffbd;
  --colors-success-200: #b2ffd6;
  --colors-success-100: #d1ffe6;
  --colors-primary-50: #f9f5ff;
  --colors-primary-25: #fcfaff;
  --colors-grey-50: #f6f6f8;
  --colors-grey-25: #fff;
  --colors-success-50: #effff6;
  --colors-success-25: #f5fffa;
  --colors-secondary-900: #054f31;
  --colors-secondary-800: #05603a;
  --colors-secondary-700: #027948;
  --colors-secondary-600: #039855;
  --colors-secondary-500: #12b76a;
  --colors-secondary-400: #32d583;
  --colors-secondary-300: #6ce9a6;
  --colors-secondary-200: #a6f4c5;
  --colors-secondary-100: #d1fadf;
  --colors-secondary-50: #ecfdf3;
  --colors-secondary-25: #f6fef9;
  --colors-error-900: #7a271a;
  --colors-error-800: #912018;
  --colors-error-700: #b32318;
  --colors-error-600: #d92d20;
  --colors-error-500: #f04438;
  --colors-error-400: #f97066;
  --colors-error-300: #fda19b;
  --colors-error-200: #fecdc9;
  --colors-error-100: #fee4e2;
  --colors-error-50: #fef3f2;
  --colors-error-25: #fffafa;
  --colors-warning-900: #792e0d;
  --colors-warning-800: #93370d;
  --colors-warning-700: #b54708;
  --colors-warning-600: #dc6803;
  --colors-warning-500: #f79009;
  --colors-warning-400: #fdb022;
  --colors-warning-300: #fec84b;
  --colors-warning-200: #fedf89;
  --colors-warning-100: #feefc6;
  --colors-warning-50: #fffaeb;
  --colors-warning-25: #fffcf5;


}