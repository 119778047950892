.ql-container {

  background-color: var(--colors-grey-25);
  height: 40vh;
  /*Світло-сірийфон*/
  min-height: 300px;
  border-radius: 0 0 16px 16px;
  -webkit-border-radius: 0 0 16px 16px;
  -moz-border-radius: 0 0 16px 16px;
  -ms-border-radius: 0 0 16px 16px;
  -o-border-radius: 0 0 16px 16px;
  overflow-y: auto;
}

.ql-toolbar {
  margin-top: 12px;
  border: none;
  border-radius: 16px 16px 0 0;
  color: var(--colors-grey-25);
  background-color: var(--colors-primary-600);

  -webkit-border-radius: 16px 16px 0 0;
  -moz-border-radius: 16px 16px 0 0;
  -ms-border-radius: 16px 16px 0 0;
  -o-border-radius: 16px 16px 0 0;
}

.ql-toolbar ::before {

  color: var(--colors-grey-25);
  /* Колір панелі інструментів */
}

.ql-toolbar .ql-stroke {
  stroke: var(--colors-grey-25) !important;
}

.ql-toolbar .ql-fill {
  fill: var(--colors-grey-25) !important;
}

.ql-picker-item::before {

  color: var(--colors-grey-900);
}

.ql-picker-options {
  background-color: var(--colors-primary-600) !important;
}