@import url('./styles/vars.css');

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/MANROPE-VARIABLEFONT_WGHT.TTF') format('truetype');
  font-weight: 100 900;
  /* Діапазон ваги від 100 до 900 */
  font-style: normal;
}

input::placeholder {
  color: var(--secondary-text-color);
}

input:-internal-autofill-selected {
  appearance: none;
  background-image: none !important;
  background-color: #fff !important;
  color: inherit !important;
}

input[type="password"] {
  -webkit-text-security: none !important;
  padding-block: 0;

}

input:not([type="file"], [type="image"], [type="checkbox"], [type="radio"]) {
  /* Ваші стилі */
}

*,
:after,
:before {
  box-sizing: border-box;
}